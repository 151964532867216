import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min';
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import '../styles/app.scss';

$(document).ready(() => {

    $(window).scroll(() => {
        const winTop = $(window).scrollTop();

        if (winTop >= 100) {
            $("body").addClass("sticky-header");
        } else {
            $("body").removeClass("sticky-header");
        }
    });

    $('.slider').slick({
        arrows: false,
        autoplay: true,
        dots: true,
        lazyLoad: 'progressive',
        fade: false
    });
});